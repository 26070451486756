const config = {
    name: 'Marina & Dražen',
    date: '23.11.2024.',
    subdomain: 'marinaidrazen23112024',
    password: 'marina563',
    maxImage: 25
};


// PROMIJENI INDEX TITLE

export default config;